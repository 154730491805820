<template>
  <div style="min-height: 700px">
    <BreadCrumbs>خانه > ورود</BreadCrumbs>
    <v-container style="max-width: 664px">
        <div class="login" @keyup.enter="loginUser" v-if="state==='loginUsername'">
          <v-alert
              border="left"
              color="primary"
              type="error"
              icon="mdi-alert-circle-outline"
              v-if="wronglogin"
          >
            نام کاربری یا کلمه عبور اشتباه است
          </v-alert>
          <span class="logintitle mb-3">لطفا برای ورود نام کاربری و کلمه عبور خود را وارد کنید.</span>

          <v-text-field
              background-color="white"
              label="نام کاربری"
              height="50"
              outlined
              v-model="username"
              color="#570654"
              class="mt-4 rounded-0 "
          ></v-text-field>

          <v-text-field
              background-color="white"
              label="کلمه عبور"
              height="50"
              v-model="password"
              :type="passshow ? 'text' : 'password'"
              outlined
              color="#570654"
              class="mb-4 rounded-0 "
          >
            <template v-slot:append>
              <div style="cursor: pointer;margin-top: 3px" @click="passshow= !passshow" >
                <img v-if="passshow" src="../assets/image/Public Icons/eye-icon.png" height="27.2" width="27.2"/>
                <img v-else src="../assets/image/Public Icons/eye-off.png" height="27.2" width="27.2"/>
              </div>
            </template>
          </v-text-field>

          <div class="d-flex flex-column flex-sm-row justify-space-between align-center my-6">

            <span @click="state='resetPass'" class="logingreya mb-4 mb-sm-0" >کلمه عبور را فراموش کردم</span>


            <v-btn
                min-width="158"
                class="btn"
                :loading="loading"
                @click="loginUser"
            >
              ورود
            </v-btn>

          </div>

          <v-divider class="my-6"></v-divider>
          <div class="d-flex flex-column flex-sm-row justify-center  justify-sm-space-between">
          <span class="logina text-center" @click="state='loginMobile'"> ورود با شماره موبایل </span>
          <router-link to="/register" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
            <v-btn
                min-width="158"
                class="btn"
                @click="navigate" role="link"
            >
              <span style="font-size: 18px" >ثبت نام</span>
            </v-btn>
          </router-link>
          </div>

        </div>
        <div class="login" v-if="state==='loginMobile'">
          <span class="logintitle mb-3">لطفا برای ورود شماره موبایل خود را وارد کنید.</span>

          <v-text-field
              background-color="white"
              v-if="status==='tell'"
              label="شماره موبایل"
              height="50"
              :rules="[tellRules.required,tellRules.pattern]"
              v-model="tell"
              :error-messages="tellErrors"
              @input="$v.tell.$touch()"
              @blur="$v.tell.$touch()"
              outlined
              color="#570654"
              class="my-4 rounded-0"
          ></v-text-field>

          <v-text-field
              v-if="status==='code'"
              background-color="white"
              label="کد تایید"
              height="50"
              v-model="code"
              outlined
              color="#570654"
              class="my-4 rounded-0"
          ></v-text-field>

            <v-btn
                v-if="status==='tell'"
                min-width="158"
                :loading="loading"
                class="btn mr-auto d-block my-6 "
                @click="tellLogin"
            >
              ارسال کد تایید
            </v-btn>

          <v-btn
              v-if="status==='code'"
              :loading="loading"
              min-width="158"
              class="btn mr-auto d-block my-6 "
              @click="codeLogin"
          >
            تایید
          </v-btn>

          <v-divider class="my-6"></v-divider>

          <div class="d-flex flex-column flex-sm-row justify-center  justify-sm-space-between">
            <span class="logina text-center" @click="state='loginUsername'"> ورود با نام کاربری </span>
            <router-link to="/register" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
              <v-btn
                  min-width="158"
                  class="btn"
                  @click="navigate" role="link"
              >
                <span style="font-size: 18px" >ثبت نام</span>
              </v-btn>
            </router-link>
          </div>
        </div>
        <div class="login" v-if="state==='resetPass'">
          <span class="logintitle mb-3">لطفا شماره موبایل خود را وارد کنید.</span>

          <v-text-field
              background-color="white"
              label="شماره موبایل"
              height="50"
              :rules="[tellRules.required,tellRules.pattern]"
              v-model="tell"
              :error-messages="tellErrors"
              @input="$v.tell.$touch()"
              @blur="$v.tell.$touch()"
              outlined
              color="#570654"
              class="my-4 rounded-0"
          ></v-text-field>

            <v-btn
                min-width="158"
                :loading="loading"
                class="btn mr-auto d-block my-6 "
                @click="resetPass()"
            >
              بازنشانی کلمه عبور
            </v-btn>

          <v-divider class="my-6"></v-divider>

          <div class="d-flex flex-column flex-sm-row justify-center  justify-sm-space-between">
            <span class="logina text-center" @click="state='loginUsername'"> ورود با نام کاربری </span>
            <router-link to="/register" style="text-decoration: none;cursor: pointer" custom v-slot="{ navigate }">
              <v-btn
                  min-width="158"
                  class="btn"
                  @click="navigate" role="link"
              >
                <span style="font-size: 18px" >ثبت نام</span>
              </v-btn>
            </router-link>
          </div>
        </div>
    </v-container>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import { validationMixin } from 'vuelidate'
import {required} from 'vuelidate/lib/validators'
import axios from "axios";
export default {
  name: 'App',
  mixins : [validationMixin],
  data() {
    return {
      passshow: false,
      state:'loginUsername',
      loading : false,
      username : '',
      password : '',
      status : 'tell',
      code : '',
      loginId: '',
      userId : '',
      wronglogin : false,
      logindata : null,
      tell : '',
      tellRules: {
        required: value => !!value ||
            'شماره تماس را وارد کنید',
        pattern: value => {
          const pattern = /^(\+98|0)?9\d{9}$/
          return pattern.test(value) || 'شماره تماس نا معتبر است'
        },
      },
    }
  },
  components : {
    BreadCrumbs,
  },
  validations : {
    tell: {
      required,
    },
  },
  computed : {
    tellErrors () {
      const errors = []
      if (!this.$v.tell.$dirty) return errors
      !this.$v.tell.required && errors.push('شماره تماس را وارد کنید')
      return errors
    },
    numberValidate() {
      return this.tellRules.pattern(this.tell) === true ;
    },
  },
  metaInfo() {
    return {
      title:'ورود',
      link: [{rel: 'canonical', href: this.$store.state.url+'/login'}]
    }
  },
  methods : {
    tellLogin(){
      this.$v.$touch();
      if (this.$v.$invalid || !this.numberValidate) {
        console.log('Err');
      } else {
        this.loading=true;
        axios.post(this.$store.state.api + 'tell', {
          tell: this.tell,
          role: 'work',
          secure: 1
        }).then(response => {
          let res = response.data.data;
          if (response.data.result) {
            if (res.access) {
              this.loading = false;
              this.loginId = res.loginId;
              this.userId = res.userId;
              this.$store.state.notify.is = true;
              this.$store.state.notify.text = 'ارسال شد';
              this.status = 'code';
            } else {
              this.loading = false;
              this.$store.state.notify.is = true;
              this.$store.state.notify.text = 'شماره موجود نیست';
            }
          }
        });
      }
    },
    resetPass(){
      this.$v.$touch();
      if (this.$v.$invalid || !this.numberValidate) {
        console.log('Err');
      } else {
        this.loading=true;
        axios.post(this.$store.state.api + 'resetuserpass', {
          tell: this.tell,
        }).then(response => {
          if (response.data.result) {
              this.loading = false;
              this.$store.state.notify.is = true;
              this.$store.state.notify.text = 'نام کاربری و کلمه عبور برای شما ارسال شد';
              this.state = 'loginUsername';
          }else {
            this.$store.state.notify.is = true;
            this.$store.state.notify.text = response.data.message;
          }
        });
      }
    },
    codeLogin(){
      this.loading = true;
      axios.post(this.$store.state.api + 'checkcode', {id: this.loginId, code: this.code}).then(response => {
        if (response.data.result) {
          this.login(this.userId);
          this.loading = false;
        }else {
          this.loading = false;
          this.$store.state.notify.is = true;
          this.$store.state.notify.text = 'کد را اشتباه زدید';
        }
      });
    },
    loginUser(){
      this.loading=true;
      axios.post(this.$store.state.api + 'tell', {
        username:this.username,
        password:this.password,
        role: 'work',
      }).then(response => {
        this.logindata = response.data.data;
        if (response.data.result && this.logindata.access) {
          this.loading=false;
          this.login(this.logindata.userId);
        } else {
          this.loading=false;
          this.wronglogin=true;
          return false;
        }
      });
    },
    login(userId){
      localStorage.setItem('token', (userId + 1100) * 1234);
      this.$store.state.userId = userId;
      axios.post(this.$store.state.api + 'getwork', {
        userId : this.$store.state.userId,
      }).then(response => {
        this.title = response.data.data.title;
        localStorage.setItem('title', (this.title));
        this.$store.state.userTitle = this.title;
      });
      this.$store.state.notify.is = true;
      this.$store.state.notify.text = 'خوش آمدید!';
      this.$router.push('/profile');
    },
  }
};
</script>


<style lang="scss">
.login{
  width: 100%;
  margin-top: 100px;
  background-color: #fcfcfc;
  border-radius: 8px;
  padding: 15px 14px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
  transition: 0.3s all
}


@media only screen and (min-width: 600px) {
  .login{
    padding: 15px 28px;
  }
}



.logintitle{
  font-size: 18px;
  color: #570654;
}
.logina{
  font-size: 20px;
  color: #570654;
  cursor: pointer;
}
.logingreya{
  font-size: 14px;
  color: #a1a1a1;
  cursor: pointer;
}

.login .v-input .v-label{
  color: #a3a3a3 !important;
  font-size: 14px !important;
}

.login .v-text-field .v-label--active{
  color: #570654 !important;
}

.login .v-input--is-focused fieldset {
  border-color: #570654 !important;
}

.login .error--text fieldset {
  border-color: #ff6969 !important;
}

.login .v-text-field fieldset{
  border-color: #e8e8e8 ;
}

.login .v-input__slot{
  padding: 0 12px !important;
  margin-bottom: 4px !important;
}

.login .v-input__append-inner{
  margin-top: 12px !important;
}


.login .v-text-field__details{
  margin-bottom: 4px !important;
}

.login .v-input{
  font-size: 14px !important;
}

</style>
